import "./Style.scss";

function Banner(props){
    return (
        <div className="banner">
            <img src={props.src} />
        </div>
    );
    
}
export default Banner;