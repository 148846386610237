import Button from "./Button";
import Logo from "./Logo";
import React, { useState } from 'react';
import { Link } from "react-router-dom";

function Header(attributes){
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    return(
        <div className="header">
           <div className="container">
                <div className="displayFlex navbar navbar-expand-lg navbar-dark">
                    <div>
                        <Logo className="logoWhite pt-1 pb-1" />
                    </div>

                    <div style={{marginTop: "5px"}}>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNavAltMarkup">
                            <div className="navbar-nav align-items-center">
                                {/* <Link to={process.env.PUBLIC_URL} className={"nav-item nav-link" + " " + (attributes.active == "home"? "active" : "")} href="#">Home</Link> */}
                                <Link to={process.env.PUBLIC_URL + "/games"} className={"nav-item nav-link" + " " + (attributes.active == "portfolio"? "active" : "")} href="#">Portfolio</Link>
                                <Link to={process.env.PUBLIC_URL + "/services"} className={"nav-item nav-link" + " " + (attributes.active == "services"? "active" : "")} href="#">Services</Link>
                                <Link to={process.env.PUBLIC_URL + "/about"} className={"nav-item nav-link" + " " + (attributes.active == "about"? "active" : "")} href="#">About Us</Link>
                                <Link to={process.env.PUBLIC_URL + "/contact"} className={"nav-item nav-link" + " " + (attributes.active == "contact"? "active" : "")} href="#">Contact</Link>
                            </div>
                        </div>
                    </div>

                    <div style={{marginTop: "7px"}}>
                        {/* <Button href="http://casino.astungaming.com/" name="Play Games" className="filled blueTheme"></Button> */}
                        <a target="new" title="Open in a new tab" href="http://casino.astungaming.com/">
                            <button type="button" className="btn customButton filled blueTheme">
                                Play Games
                            </button> 
                        </a>
                    </div>
                </div>
           </div>
        </div>
    );
}
export default Header;