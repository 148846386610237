import "./Style.scss";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { SubmitContactForm } from "../Service";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SocialMedia from "../components/SocialMedia";
import Address from "../components/Address";
import JoinUs from "../components/JoinUs";
import Button from "../components/Button";
import Banner from "../components/Banner";


function Contact(){
    const [confirmation, setConfirmation] = useState(null);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();

    const onSubmit = (data, e) => {    
        // console.log("Submit handler:", data);
        setConfirmation("Sending");
        //
        SubmitContactForm(data).then(function(response){
            setConfirmation("Sent");
            reset();
            setTimeout(()=>{
                setConfirmation(null);
            },3000);
        })
    };
    
    const onError = (errors, e) => {
        console.log("error handler:", errors);
        setConfirmation("Error");
        reset();
        setTimeout(()=>{
            setConfirmation(null);
        },3000);
    };
    
    return(
        <div className="page contactUs">
            <Header active="contact" />

            <Banner src="./assets/images/bannerContactUs.jpg"></Banner>
            
            <div className="seperator"><div className="container"></div></div>

            {confirmation?(
                <div className="section border wh100" style={{position:"fixed", top:"0px", zIndex:10, backgroundColor:"rgba(0,0,0,0.4)"}}>
                    <div className="container">
                        <div style={{backgroundColor:"grey", border:"solid 3px white", borderRadius:"10px", width:"70%", margin:"auto", color:"#404040", padding: "50px 30px", marginTop:"100px", backgroundColor: confirmation === "Error"?"rgb(237, 158, 158, 96%)":confirmation === "Sending"?"rgb(227, 215, 158, 0.9)":"rgba(185, 231, 185, 0.9)"}}>
                            <span style={{fontWeight:"bold", fontSize:"22px"}}>{(confirmation === "Error")?"Error:":(confirmation === "Sending")?"Please wait!":"Thank you!"}</span>
                            <br></br>
                            {(confirmation === "Error")?"Sorry, there was an error sending your message. Please drop an email at 'contact.astungaming.com'.":(confirmation === "Sending")?"While we are sending your message.":"Your message has been sent successfully."}
                        </div>
                    </div>
                </div>
            ):(
                <></>
            )}

            <div className="section">
                <div className="container">
                    <h1 className="colorWhite text-start" style={{marginBottom:"30px"}}>Contact Us</h1>
                    
                    <div className="row text-start">
                        <div className="col-12 col-md-8">
                            <form onSubmit={handleSubmit(onSubmit, onError)}>
                                <div className="row">
                                    <div className="col-6 mb-3">
                                        <label className="form-label">First name *</label>
                                        <input type="text" className="form-control" id="inputFirstName" aria-describedby="firstNameHelp" defaultValue="" {...register("firstName", { required: true })} />
                                        {errors.firstName?(<span>First name is required</span>):(<span>&nbsp;</span>)}
                                    </div>
                                    <div className="col-6 mb-3">
                                        <label className="form-label">Last name</label>
                                        <input type="text" className="form-control" id="inputLastName" aria-describedby="firstNameHelp" defaultValue="" {...register("lastName")} />
                                    </div>
                                </div>
                                
                                <div className="mb-3">
                                    <label className="form-label">Email address*</label>
                                    <input type="email" className="form-control" id="inputEmail" aria-describedby="emailHelp" defaultValue="" {...register("email", { required: true })} />
                                    {errors.email?(<span>Email is required</span>):(<span>&nbsp;</span>)}
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Phone*</label>
                                    <input type="phone" className="form-control" id="inputPhone" aria-describedby="phoneHelp" defaultValue="" {...register("phone", { required: true })} />
                                    {errors.phone?(<span>Phone is required</span>):(<span>&nbsp;</span>)}
                                </div>
                                
                                <div className="mb-3">
                                    <label className="form-label">Message*</label>
                                    <textarea className="form-control" id="inputMessage" style={{minHeight: "130px"}} defaultValue="" {...register("message", { required: true })}></textarea>
                                    {errors.message?(<span>Message is required</span>):(<span>&nbsp;</span>)}
                                </div>
                                
                                <button type="submit" className={"btn customButton filled blueTheme"} style={{marginTop:"30px", width: "150px", height: "50px"}}>
                                    Submit
                                </button>

                            </form>
                        </div>

                        <div className="col-12 col-md-1"></div>

                        <div className="col-12 col-md-3 text-center text-md-start mt-5 mt-md-0">
                            <div className="mb-5">
                                <div className="pageHeading">
                                    <h2>&nbsp;</h2>
                                </div>
                                <Address heading="Our address" />
                            </div>

                            <div className="mb-5">
                                <h4 className="text-uppercase" style={{color:"#AAA"}}>Email</h4>
                                <a href="mailto:contact@astungaming.com">contact@astungaming.com</a>
                            </div>

                            <div className="mb-5">
                                <h4 className="text-uppercase" style={{color:"#AAA"}}>Phone</h4>
                                <a href="tel:+918588865196">+91-85888 65196</a>
                            </div>

                            <SocialMedia heading="Follow Us" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="seperator"><div className="container"></div></div>

            <JoinUs></JoinUs>

            <Footer />
        </div>
    );
}

export default Contact;