import { Link } from "react-router-dom";
import Address from "./Address";
import Logo from "./Logo";
import Platforms from "./Platforms";
import SocialMedia from "./SocialMedia";

function Footer(){
    return(
        <div className="footer">
            <div className="container">
                <div className="footerContent row">

                    <div className="col-12 col-md-6 col-lg-3 text-center text-md-start">
                        <Logo className="medium m-md-0"/>
                        <div className="descriptionText m-0 mt-2 mb-4 text-center text-md-start" style={{color:"#F00"}}>VISIT OUR PORTFOLIO AREA</div>
                        <Address heading="" onlyHeadquarters />
                    </div>

                    <div className="col-12 col-md-6 col-lg-3 text-center text-md-start mt-3 mt-md-0">
                        <div>
                            <h4 className="text-uppercase" style={{color:"#AAA"}}>Discover</h4>
                            <ul className="listNav">
                                <li><Link to={process.env.PUBLIC_URL}>Home</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/games"}>Portfolio</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/services"}>Services</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/about"}>About Us</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact Us</Link></li>
                                {/* <li><Link to={process.env.PUBLIC_URL + "/career"}>Careers</Link></li> */}
                            </ul>
                        </div>
                    </div>
                    
                    <div className="col-12 col-md-6 col-lg-3 text-center text-md-start mt-3 mt-md-0">
                        <div>
                            <h4 className="text-uppercase" style={{color:"#AAA"}}>Contact Us</h4>
                            <p className="mt-1 mb-4">
                                {/* Mail to documentation: https://css-tricks.com/snippets/html/mailto-links/ */}
                                <a href="mailto:contact@astungaming.com">contact@astungaming.com</a>
                            </p>
                        </div>

                        <div className="mt-5">
                            <h4 className="text-uppercase" style={{color:"#AAA"}}>Phone</h4>
                            <p className="mt-1 mb-4">
                                {/* Mail to documentation: https://css-tricks.com/snippets/html/mailto-links/ */}
                                <a href="tel:+918588865196">+91-85888 65196</a>
                            </p>
                        </div>
                        
                        {/* <div>
                            <h4>LEGAL</h4>
                            <p className="mt-1 mb-4">
                                <Link to={process.env.PUBLIC_URL + "/privacy"}>Privacy & Cookie Policy</Link>
                            </p>
                        </div> */}

                        {/* <Platforms heading="Download" /> */}
                    </div>
                    
                    <div className="col-12 col-md-6 col-lg-3 text-center text-md-start mt-3 mt-md-0">
                        {/* <div>
                            <h4>OUR LICENCES</h4>
                            <p className="textJustify">
                                Astun Gaming Malta Ltd. is licensed and regulated VISIT OUR CLIENT AREA by the Malta Gaming 
                                Authority (MGA) for a Critical Gaming Supply Licence (Type 1 Gaming Services) under license 
                                number MGA/ B2B/779/2020 issued on 27/01/2020.
                            </p>
                        </div> */}
                        
                        <SocialMedia className="m-auto" heading="Follow us" />
                        
                        <Platforms heading="Platforms" />
                    </div>

                </div>
            </div>

            <div className="copyRight">
                <div className="container d-flex justify-content-between">
                    <div>
                        © <span style={{color:"#888"}}>Astun Gaming {(new Date().getFullYear())}</span>. All rights reserved. {/* by <span className="colorAAA">Astun Gaming</span> */}
                    </div>
                    <div className="d-flex">
                        <Logo className="extraSmall" />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Footer;